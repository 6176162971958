module.exports = [{
      plugin: require('/Users/opodame/Code/odame-personal-website/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/opodame/Code/odame-personal-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-127661885-1"},
    },{
      plugin: require('/Users/opodame/Code/odame-personal-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
